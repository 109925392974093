import privacy from '../../../assets/images/privacy.jpg';
import { useEffect } from 'react';

const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>

<div className="relative w-full h-96">
        <img src={privacy} alt="Privacy" className="w-full h-full object-cover" />
        <div className="absolute inset-0 flex items-center justify-center">
            <p className="text-white text-xl md:text-3xl lg:text-5xl font-bold bg-opacity-50 p-4 rounded">
                Privacy Policy
            </p>
        </div>
    </div>
    <h1
        style={{
            margin: '20.0pt 0 6.0pt 0',
            lineHeight: '132%',
            fontSize: '27px',
            fontFamily: '"Arial", sans-serif',
            fontWeight: 'normal',
            background: 'white',
        }}
    >
        <span style={{ fontSize: '31px', lineHeight: '132%', fontFamily: 'Roboto' }}>&nbsp;</span>
    </h1>
    <p
        style={{
            margin: '0cm',
            lineHeight: '115%',
            fontSize: '15px',
            fontFamily: '"Arial", sans-serif',
            background: 'white',
        }}
    >
        <span style={{ fontSize: '13px', lineHeight: '115%', fontFamily: 'Roboto', color: '#ED7474' }}>
            &nbsp;
        </span>
    </p>
    <p
        style={{
            margin: '0cm',
            lineHeight: '115%',
            fontSize: '15px',
            fontFamily: '"Arial", sans-serif',
            background: 'white',
        }}
    >
        <span style={{ fontSize: '26px', lineHeight: '115%', fontFamily: 'Roboto', color: '#6C7794' }}>
            PRIVACY POLICY
        </span>
    </p>
    <p
        style={{
            margin: '0cm',
            lineHeight: '115%',
            fontSize: '15px',
            fontFamily: '"Arial", sans-serif',
            background: 'white',
        }}
    >
        <span style={{ fontFamily: 'Roboto', color: '#6C7794' }}>&nbsp;</span>
    </p>
    <p
        style={{
            margin: '0cm',
            lineHeight: '115%',
            fontSize: '15px',
            fontFamily: '"Arial", sans-serif',
            background: 'white',
        }}
    >
        <span
            style={{
                fontSize: '15px',
                lineHeight: '115%',
                fontFamily: 'Roboto',
                color: '#7F7F7F',
            }}
        >
            Last updated 13<sup>th</sup> Nov, 2024
        </span>
    </p>
    <p
        style={{
            margin: '0cm',
            lineHeight: '150%',
            fontSize: '15px',
            fontFamily: '"Arial", sans-serif',
            background: 'white',
        }}
    >
        <span
            style={{
                fontSize: '15px',
                lineHeight: '150%',
                fontFamily: 'Roboto',
                color: '#595959',
            }}
        >
            This privacy notice for TheColors App, describes how and why we might collect, store, use,
            and/or share ('process') your information when you use our services ('Services'), such as
            when you:
        </span>
    </p>
    <p>
        <span
            style={{
                fontSize: '15px',
                lineHeight: '150%',
                fontFamily: 'Roboto',
                color: '#7F7F7F',
            }}
        >
            Download and use our mobile application (TheColors App), or any other application of ours
            that links to this privacy notice
        </span>
        <span
            style={{
                fontSize: '15px',
                lineHeight: '150%',
                fontFamily: 'Roboto',
                color: '#7F7F7F',
            }}
        >
            Engage with us in other related ways, including any sales, marketing, or events
        </span>
    </p>
    <p
        style={{
            margin: '0cm',
            lineHeight: '150%',
            fontSize: '15px',
            fontFamily: '"Arial", sans-serif',
            background: 'white',
        }}
    >
        <span
            style={{
                fontSize: '15px',
                lineHeight: '150%',
                fontFamily: 'Roboto',
                color: '#7F7F7F',
            }}
        >
            Questions or concerns? Reading this privacy notice will help you understand your privacy
            rights and choices. If you do not agree with our policies and practices, please do not use
            our Services. If you still have any questions or concerns, please contact us at
            info@game-sphere.io.
        </span>
    </p>
    <p
        style={{
            margin: '0cm',
            lineHeight: '150%',
            fontSize: '15px',
            fontFamily: '"Arial", sans-serif',
            background: 'white',
        }}
    >
        <a
            href="https://app.termly.io/dashboard/website/3179e582-4f68-4a7f-ac9e-55c76b47a362/privacy-policy#infocollect"
            style={{
                textDecoration: 'none',
                color: '#595959',
                fontSize: '15px',
                lineHeight: '150%',
                fontFamily: 'Roboto',
            }}
        >
            1. WHAT INFORMATION DO WE COLLECT?
        </a>
    </p>
    <p
        style={{
            margin: '0cm',
            lineHeight: '150%',
            fontSize: '15px',
            fontFamily: '"Arial", sans-serif',
            background: 'white',
        }}
    >
        <a
            href="https://app.termly.io/dashboard/website/3179e582-4f68-4a7f-ac9e-55c76b47a362/privacy-policy#infouse"
            style={{
                textDecoration: 'none',
                color: '#595959',
                fontSize: '15px',
                lineHeight: '150%',
                fontFamily: 'Roboto',
            }}
        >
            2. HOW DO WE PROCESS YOUR INFORMATION?
        </a>
    </p>
    <p
        style={{
            margin: '0cm',
            lineHeight: '150%',
            fontSize: '15px',
            fontFamily: '"Arial", sans-serif',
            background: 'white',
        }}
    >
        <span style={{ color: 'black' }}>
            <a
                href="https://app.termly.io/dashboard/website/3179e582-4f68-4a7f-ac9e-55c76b47a362/privacy-policy#caresidents"
                style={{
                    textDecoration: 'none',
                    color: '#595959',
                    fontSize: '15px',
                    lineHeight: '150%',
                    fontFamily: 'Roboto',
                }}
            >
                10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </a>
        </span>
    </p>


    <div>
<p style={{ margin: '0cm', lineHeight: '150%', fontSize: '15px', fontFamily: '"Arial", sans-serif', background: 'white' }}>
<span style={{ color: 'black' }}>
<a
href="https://app.termly.io/dashboard/website/3179e582-4f68-4a7f-ac9e-55c76b47a362/privacy-policy#policyupdates"
style={{ fontSize: '15px', lineHeight: '150%', fontFamily: 'Roboto', color: '#595959', textDecoration: 'none' }}
>
11. DO WE MAKE UPDATES TO THIS NOTICE?
</a>
</span>
</p>

<p style={{ margin: '0cm', lineHeight: '150%', fontSize: '15px', fontFamily: '"Arial", sans-serif', background: 'white' }}>
<span style={{ color: 'black' }}>
<a
href="https://app.termly.io/dashboard/website/3179e582-4f68-4a7f-ac9e-55c76b47a362/privacy-policy#contact"
style={{ fontSize: '15px', lineHeight: '150%', fontFamily: 'Roboto', color: '#595959', textDecoration: 'none' }}
>
12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
</a>
</span>
</p>
<p style={{ margin: '0cm', lineHeight: '150%', fontSize: '15px', fontFamily: '"Arial", sans-serif', background: 'white' }}>
<span style={{ fontFamily: 'Roboto', color: '#595959', textDecoration: 'none' }}>
13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
</span>
</p>
<p style={{ margin: '0cm', lineHeight: '150%', fontSize: '15px', fontFamily: '"Arial", sans-serif', background: 'white' }}>
<span style={{ color: '#6C7794' }}>&nbsp;</span>
</p>
<p style={{ margin: '0cm', lineHeight: '150%', fontSize: '15px', fontFamily: '"Arial", sans-serif', background: 'white' }}>
<span style={{ fontSize: '15px', lineHeight: '150%', fontFamily: 'Roboto', color: 'black' }}>
1. WHAT INFORMATION DO WE COLLECT?
</span>
</p>
<p style={{ margin: '0cm', lineHeight: '150%', fontSize: '15px', fontFamily: '"Arial", sans-serif', background: 'white' }}>
<span style={{ color: '#6C7794' }}>&nbsp;</span>
</p>
<p style={{ margin: '0cm', lineHeight: '150%', fontSize: '15px', fontFamily: '"Arial", sans-serif', background: 'white' }}>
<span style={{ fontSize: '15px', lineHeight: '150%', fontFamily: 'Roboto', color: 'black' }}>
Personal information you disclose to us
</span>
</p>
<p style={{ margin: '0cm', lineHeight: '150%', fontSize: '15px', fontFamily: '"Arial", sans-serif', background: 'white' }}>
<span style={{ color: '#6C7794' }}>&nbsp;</span>
</p>
<p style={{ margin: '0cm', lineHeight: '150%', fontSize: '15px', fontFamily: '"Arial", sans-serif', background: 'white' }}>
<em>
<span style={{ fontSize: '15px', lineHeight: '150%', fontFamily: 'Roboto', color: '#595959' }}>
In Short: We collect personal information that you provide to us.
</span>
</em>
</p>
<p style={{ margin: '0cm', lineHeight: '150%', fontSize: '15px', fontFamily: '"Arial", sans-serif', background: 'white' }}>
<span style={{ color: '#6C7794' }}>&nbsp;</span>
</p>
<p style={{ margin: '0cm', lineHeight: '150%', fontSize: '15px', fontFamily: '"Arial", sans-serif', background: 'white' }}>
<span style={{ fontSize: '15px', lineHeight: '150%', fontFamily: 'Roboto', color: '#595959' }}>
We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.
</span>
</p>
<p style={{ margin: '0cm', lineHeight: '150%', fontSize: '15px', fontFamily: '"Arial", sans-serif', background: 'white' }}>
<span style={{ color: '#6C7794' }}>&nbsp;</span>
</p>
<p style={{ margin: '0cm', lineHeight: '150%', fontSize: '15px', fontFamily: '"Arial", sans-serif', background: 'white' }}>
<span style={{ fontSize: '15px', lineHeight: '150%', fontFamily: 'Roboto', color: '#595959' }}>
Personal Information Provided by You. The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:
</span>
</p>
<ul style={{ listStyleType: 'none', marginLeft: '26px' }}>
<li>
<span style={{ lineHeight: '150%', fontFamily: 'Roboto', color: '#595959' }}>names</span>
</li>
</ul>
</div>


<span style={{ lineHeight: '150%', fontFamily: 'Roboto', color: '#595959' }}>phone number</span>
<ul>
<li>
<span style={{ lineHeight: '150%', fontFamily: 'Roboto', color: '#595959' }}>email addresses</span>
</li>
<li>
<span style={{ lineHeight: '150%', fontFamily: 'Roboto', color: '#595959' }}>mailing addresses</span>
</li>
<li>
<span style={{ lineHeight: '150%', fontFamily: 'Roboto', color: '#595959' }}>usernames</span>
</li>
<li>
<span style={{ lineHeight: '150%', fontFamily: 'Roboto', color: '#595959' }}>passwords</span>
</li>
<li>
<span style={{ lineHeight: '150%', fontFamily: 'Roboto', color: '#595959' }}>contact preferences</span>
</li>
<li>
<span style={{ lineHeight: '150%', fontFamily: 'Roboto', color: '#595959' }}>contact or authentication data</span>
</li>
</ul>
<p style={{ margin: '0cm', lineHeight: '150%', fontSize: '15px', fontFamily: '"Arial", sans-serif', background: 'white' }}>
<span style={{ fontSize: '15px', lineHeight: '150%', fontFamily: 'Roboto', color: '#6C7794' }}>
Sensitive Information. We do not process sensitive information.
</span>
</p>
<p style={{ margin: '0cm', lineHeight: '150%', fontSize: '15px', fontFamily: '"Arial", sans-serif', background: 'white' }}>
<span style={{ color: '#6C7794' }}>&nbsp;</span>
</p>
<p style={{ margin: '0cm', lineHeight: '150%', fontSize: '15px', fontFamily: '"Arial", sans-serif', background: 'white' }}>
<span style={{ fontSize: '15px', lineHeight: '150%', fontFamily: 'Roboto', color: '#595959' }}>
Social Media Login Data. We may provide you with the option to register with us using your existing social media account details, like your Google social media account. If you choose to register in this way, we will collect the information described in the section called&nbsp;
</span>
<span style={{ color: 'black' }}>
<a href="https://app.termly.io/dashboard/website/3179e582-4f68-4a7f-ac9e-55c76b47a362/privacy-policy#sociallogins">
<span style={{ fontSize: '15px', lineHeight: '150%', fontFamily: 'Roboto', color: '#1155CC', textDecoration: 'none' }}>
  HOW DO WE HANDLE YOUR SOCIAL LOGINS?
</span>
</a>
</span>
</p>
<p style={{ margin: '0cm', lineHeight: '150%', fontSize: '15px', fontFamily: '"Arial", sans-serif', background: 'white' }}>
<span style={{ fontSize: '15px', lineHeight: '150%', fontFamily: 'Roboto', color: 'black' }}>
2. HOW DO WE PROCESS YOUR INFORMATION?
</span>
</p>
<p style={{ margin: '0cm', lineHeight: '150%', fontSize: '15px', fontFamily: '"Arial", sans-serif', background: 'white' }}>
<span style={{ fontFamily: 'Roboto', color: '#6C7794' }}>&nbsp;</span>
</p>
<p style={{ margin: '0cm', lineHeight: '150%', fontSize: '15px', fontFamily: '"Arial", sans-serif', background: 'white' }}>
<em>
<span style={{ fontSize: '15px', lineHeight: '150%', fontFamily: 'Roboto', color: '#595959' }}>
In Short: We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.
</span>
</em>
</p>
<p style={{ margin: '0cm', lineHeight: '150%', fontSize: '15px', fontFamily: '"Arial", sans-serif', background: 'white' }}>
<span style={{ fontFamily: 'Roboto', color: '#6C7794' }}>&nbsp;</span>
</p>
<p style={{ margin: '0cm', lineHeight: '150%', fontSize: '15px', fontFamily: '"Arial", sans-serif', background: 'white' }}>
<span style={{ fontSize: '15px', lineHeight: '150%', fontFamily: 'Roboto', color: '#595959' }}>
We process your personal information for a variety of reasons, depending on how you interact with our Services, including:
</span>
</p>
<ul>
<li>
<span style={{ fontSize: '15px', lineHeight: '150%', fontFamily: 'Roboto', color: '#595959' }}>Various purposes based on service interaction.</span>
</li>
</ul>
<div>
      <h2 style={{ fontSize: '18px', lineHeight: '1.5', fontWeight: 'bold', color: 'black' }}>
        1. WHAT INFORMATION DO WE COLLECT?
      </h2>
      <ul style={{ listStyleType: 'disc', paddingLeft: '15px', margin: '0' }}>
        <li>
          <span style={{ lineHeight: '1.5', fontFamily: "'Roboto', sans-serif", color: '#595959' }}>
            To facilitate account creation and authentication and otherwise manage user accounts. We may process your
            information so you can create and log in to your account, as well as keep your account in working order.
          </span>
        </li>
        <li>
          <span style={{ lineHeight: '1.5', fontFamily: "'Roboto', sans-serif", color: '#595959' }}>
            To deliver and facilitate delivery of services to the user. We may process your information to provide you
            with the requested service.
          </span>
        </li>
        <li>
          <span style={{ lineHeight: '1.5', fontFamily: "'Roboto', sans-serif", color: '#6C7794' }}>
            To protect our Services. We may process your information as part of our efforts to keep our Services safe
            and secure, including fraud monitoring and prevention.
          </span>
        </li>
        <li>
          <span style={{ lineHeight: '1.5', fontFamily: "'Roboto', sans-serif", color: '#6C7794' }}>
            To administer prize draws and competitions. We may process your information to administer prize draws and
            competitions.
          </span>
        </li>
      </ul>
      <h2 style={{ fontSize: '18px', lineHeight: '1.5', fontWeight: 'bold', color: 'black' }}>
        8. WHAT ARE YOUR PRIVACY RIGHTS?
      </h2>
      <p>
        <em>
          <span style={{ lineHeight: '1.5', fontFamily: "'Roboto', sans-serif", color: '#595959' }}>
            In Short: You may review, change, or terminate your account at any time.
          </span>
        </em>
      </p>
    </div>


    <>
  <p style={{ margin: 0, lineHeight: '150%', fontSize: '15px', fontFamily: '"Arial", sans-serif', background: 'white' }}>
    <span style={{ fontFamily: 'Roboto', color: '#6C7794' }}>&nbsp;</span>
  </p>
  <p style={{ margin: 0, lineHeight: '150%', fontSize: '15px', fontFamily: '"Arial", sans-serif', background: 'white' }}>
    <span style={{ fontSize: '15px', lineHeight: '150%', fontFamily: 'Roboto', color: '#6C7794' }}>
      However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when
      applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful
      processing grounds other than consent.
    </span>
  </p>
  <p style={{ margin: 0, lineHeight: '150%', fontSize: '15px', fontFamily: '"Arial", sans-serif', background: 'white' }}>
    <span style={{ fontFamily: 'Roboto', color: '#6C7794' }}>&nbsp;</span>
  </p>
  <p style={{ margin: 0, lineHeight: '150%', fontSize: '15px', fontFamily: '"Arial", sans-serif', background: 'white' }}>
    <span style={{ fontSize: '15px', lineHeight: '150%', fontFamily: 'Roboto', color: '#6C7794' }}>Account Information</span>
  </p>
  <p style={{ margin: 0, lineHeight: '150%', fontSize: '15px', fontFamily: '"Arial", sans-serif', background: 'white' }}>
    <span style={{ fontFamily: 'Roboto', color: '#6C7794' }}>&nbsp;</span>
  </p>
  <p style={{ margin: 0, lineHeight: '150%', fontSize: '15px', fontFamily: '"Arial", sans-serif', background: 'white' }}>
    <span style={{ fontSize: '15px', lineHeight: '150%', fontFamily: 'Roboto', color: '#6C7794' }}>
      If you would at any time like to review or change the information in your account or terminate your account, you can:
    </span>
  </p>
  <ul style={{ listStyleType: 'none', marginLeft: '26px' }}>
    <li>
      <span style={{ fontFamily: 'Roboto', color: '#6C7794' }}>
        Log in to your account settings and update your user account.
      </span>
    </li>
    <li>
      <span style={{ fontFamily: 'Roboto', color: '#6C7794' }}>
        Contact us using the contact information provided.
      </span>
    </li>
  </ul>
  <p style={{ margin: 0, lineHeight: '150%', fontSize: '15px', fontFamily: '"Arial", sans-serif', background: 'white' }}>
    <span style={{ fontSize: '15px', lineHeight: '150%', fontFamily: 'Roboto', color: '#6C7794' }}>
      Upon your request to terminate your account, we will deactivate or delete your account and information from our active
      databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any
      investigations, enforce our legal terms and/or comply with applicable legal requirements.
    </span>
  </p>
</>

<>
  <p style={{ margin: 0, lineHeight: '150%', fontSize: '15px', fontFamily: '"Arial", sans-serif', background: 'white' }}>
    <span style={{ fontFamily: 'Roboto', color: '#6C7794' }}>&nbsp;</span>
  </p>
  <p style={{ margin: 0, lineHeight: '150%', fontSize: '15px', fontFamily: '"Arial", sans-serif', background: 'white' }}>
    <span style={{ fontSize: '15px', lineHeight: '150%', fontFamily: 'Roboto', color: '#6C7794' }}>
      If you have questions or comments about your privacy rights, you may email us at&nbsp;
    </span>
    <span style={{ fontSize: '15px', lineHeight: '150%', fontFamily: 'Roboto', color: '#7F7F7F' }}>
      info@game-sphere.io
    </span>
    <span style={{ fontSize: '15px', lineHeight: '150%', fontFamily: 'Roboto', color: '#6C7794' }}>.</span>
  </p>
  <p style={{ margin: 0, lineHeight: '150%', fontSize: '15px', fontFamily: '"Arial", sans-serif', background: 'white' }}>
    <span style={{ fontFamily: 'Roboto', color: '#6C7794' }}>&nbsp;</span>
  </p>
  <p style={{ margin: 0, lineHeight: '150%', fontSize: '15px', fontFamily: '"Arial", sans-serif', background: 'white' }}>
    <span style={{ fontSize: '15px', lineHeight: '150%', fontFamily: 'Roboto', color: 'black' }}>
      9. CONTROLS FOR DO-NOT-TRACK FEATURES
    </span>
  </p>
  <p style={{ margin: 0, lineHeight: '150%', fontSize: '15px', fontFamily: '"Arial", sans-serif', background: 'white' }}>
    <span style={{ fontFamily: 'Roboto', color: '#6C7794' }}>&nbsp;</span>
  </p>
  <p style={{ margin: 0, lineHeight: '150%', fontSize: '15px', fontFamily: '"Arial", sans-serif', background: 'white' }}>
    <span style={{ fontSize: '15px', lineHeight: '150%', fontFamily: 'Roboto', color: '#595959' }}>
      Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ('DNT') feature
      or setting you can activate to signal your privacy preference not to have data about your online browsing
      activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing
      DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism
      that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted
      that we must follow in the future, we will inform you about that practice in a revised version of this privacy
      notice.
    </span>
  </p>
  <p style={{ margin: 0, lineHeight: '150%', fontSize: '15px', fontFamily: '"Arial", sans-serif', background: 'white' }}>
    <span style={{ fontFamily: 'Roboto', color: '#6C7794' }}>&nbsp;</span>
  </p>
  <p style={{ margin: 0, lineHeight: '150%', fontSize: '15px', fontFamily: '"Arial", sans-serif', background: 'white' }}>
    <span style={{ fontSize: '15px', lineHeight: '150%', fontFamily: 'Roboto', color: 'black' }}>
      10. DO WE MAKE UPDATES TO THIS NOTICE?
    </span>
  </p>
  <p style={{ margin: 0, lineHeight: '150%', fontSize: '15px', fontFamily: '"Arial", sans-serif', background: 'white' }}>
    <span style={{ fontFamily: 'Roboto', color: '#6C7794' }}>&nbsp;</span>
  </p>
  <p style={{ margin: 0, lineHeight: '150%', fontSize: '15px', fontFamily: '"Arial", sans-serif', background: 'white' }}>
    <em>
      <span style={{ fontSize: '15px', lineHeight: '150%', fontFamily: 'Roboto', color: '#595959' }}>
        In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.
      </span>
    </em>
  </p>
  <p style={{ margin: 0, lineHeight: '150%', fontSize: '15px', fontFamily: '"Arial", sans-serif', background: 'white' }}>
    <span style={{ fontFamily: 'Roboto', color: '#6C7794' }}>&nbsp;</span>
  </p>
  <p style={{ margin: 0, lineHeight: '150%', fontSize: '15px', fontFamily: '"Arial", sans-serif', background: 'white' }}>
    <span style={{ fontSize: '15px', lineHeight: '150%', fontFamily: 'Roboto', color: '#595959' }}>
      We may update this privacy notice from time to time. The updated version will be indicated by an updated 'Revised'
      date and the updated version will be effective as soon as it is accessible. If we make material changes to this
      privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending
      you a notification. We encourage you to review this privacy notice frequently to be informed of how we are
      protecting your information.
    </span>
  </p>
  <p style={{ margin: 0, lineHeight: '150%', fontSize: '15px', fontFamily: '"Arial", sans-serif', background: 'white' }}>
    <span style={{ fontFamily: 'Roboto', color: '#6C7794' }}>&nbsp;</span>
  </p>
  <p style={{ margin: 0, lineHeight: '150%', fontSize: '15px', fontFamily: '"Arial", sans-serif', background: 'white' }}>
    <span style={{ fontSize: '15px', lineHeight: '150%', fontFamily: 'Roboto', color: 'black' }}>
      11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
    </span>
  </p>
  <p style={{ margin: 0, lineHeight: '150%', fontSize: '15px', fontFamily: '"Arial", sans-serif', background: 'white' }}>
    <span style={{ fontFamily: 'Roboto', color: '#6C7794' }}>&nbsp;</span>
  </p>
  <p style={{ margin: 0, lineHeight: '150%', fontSize: '15px', fontFamily: '"Arial", sans-serif', background: 'white' }}>
    <span style={{ fontSize: '15px', lineHeight: '150%', fontFamily: 'Roboto', color: '#595959' }}>
      If you have questions or comments about this notice, you may email us at&nbsp;
    </span>
    <span style={{ fontSize: '15px', lineHeight: '150%', fontFamily: 'Roboto', color: '#7F7F7F' }}>
      info@game-sphere.io
    </span>
    <span style={{ fontSize: '15px', lineHeight: '150%', fontFamily: 'Roboto', color: '#595959' }}>.</span>
  </p>
</>






        </div>
        
    );
};

export default PrivacyPolicy;










  
  





