import React from 'react';
import office from "../../../assets/images/office.png";


const ContactForm = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px', fontFamily: 'Arial, sans-serif', background: '#fff', color: '#000' }}>
      {/* Left Section */}
      <div style={{ width: '50%' }}>
        <h1 style={{ fontSize: '32px', fontWeight: 'bold', marginBottom: '20px', color: '#000' }}>Enter the game sphere</h1>
        <p style={{ fontSize: '16px', color: '#000', marginBottom: '20px' }}>
        Game Sphere is a blockchain-powered gaming platform revolutionizing the industry by integrating play-to-earn mechanics, true asset ownership via NFTs, and a decentralized marketplace. It empowers gamers with rewards for achievements and fosters a vibrant community through tournaments and governance. Developers gain tools to tokenize assets and build blockchain-enabled games. With cutting-edge technology, Game Sphere bridges gaming and blockchain, creating a fair, secure, and engaging ecosystem for all.
        </p>
        <div style={{ marginBottom: '20px' }}>
          <h3 style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '10px', color: '#000' }}>Our Office</h3>
          <img 
                    src={office}
                    alt="Office"
                    className="w-full h-[40vh] lg:h-[60vh] object-cover rounded-lg shadow-lg mb-6"
                />

          <p style={{ marginBottom: '5px', marginTop:'50px', color: '#000' }}>
            <strong>Unit No 1903, Floor 19</strong><br />
            JBC 2, Cluster V<br />
            JLT, Dubai
          </p>
          <p style={{ marginBottom: '5px', color: '#000' }}>
            <strong>Phone:</strong> +971 58 580 2329
          </p>
          <p style={{ marginTop: '100px', color: '#000' }}>
</p>
        </div>
      </div>

      {/* Right Section */}
      <div style={{ marginTop:'100px', width: '40%', background: '#f9f9f9', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
        <form>
          <div style={{ marginBottom: '15px' }}>
            <label htmlFor="firstName" style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold', color: '#000' }}>First Name</label>
            <input
              type="text"
              id="firstName"
              placeholder="First Name"
              style={{ width: '100%', padding: '10px', border: '1px solid #ccc', borderRadius: '4px' }}
            />
          </div>
          <div style={{ marginBottom: '15px' }}>
            <label htmlFor="lastName" style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold', color: '#000' }}>Last Name</label>
            <input
              type="text"
              id="lastName"
              placeholder="Last Name"
              style={{ width: '100%', padding: '10px', border: '1px solid #ccc', borderRadius: '4px' }}
            />
          </div>
          <div style={{ marginBottom: '15px' }}>
            <label htmlFor="email" style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold', color: '#000' }}>Email Address</label>
            <input
              type="email"
              id="email"
              placeholder="Email Address"
              style={{ width: '100%', padding: '10px', border: '1px solid #ccc', borderRadius: '4px' }}
            />
          </div>
          <div style={{ marginBottom: '15px' }}>
            <label htmlFor="message" style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold', color: '#000' }}>Your Message</label>
            <textarea
              id="message"
              placeholder="Your Message"
              style={{ width: '100%', padding: '10px', border: '1px solid #ccc', borderRadius: '4px', height: '150px' }}
            ></textarea>
          </div>
          <button
  type="submit"
  style={{
    width: '100%',
    padding: '10px',
    background: '#00008B', // Dark blue background
    color: '#FFFFFF', // White text
    fontWeight: 'bold',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  }}
>
  Send
</button>

        </form>
      </div>
    </div>
  );
};

export default ContactForm;
